<!--
 * @Autor: syq
 * @Date: 2021-07-14 10:29:44
-->
<template>
  <div id="searchbox">
    <!-- 搜索 -->
    <div class="search">
      <van-search
        v-model="searchData"
        placeholder="请输入商品名称/条形码或店铺名称进行搜索"
      
        shape="round"
        clearable
        show-action
        @search="onSearch"
      >
        <template #action>
          <div class="search-action" @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>
    <div class="content">
      <!-- 搜索历史 -->
      <div
        v-if="list.length === 0 && storelist.length === 0 && !nothing"
        class="historicalRecords"
      >
        <div class="historicalRecords-header">
          <div class="historicalRecords-header-title">搜索历史</div>
          <div
            class="historicalRecords-header-delBtn"
            @click="() => deleteHistory()"
          >
            <van-icon :name="delIcon" />
          </div>
        </div>
        <div v-if="history.length > 0" class="historicalRecords-content">
          <div
            class="recordbutton"
            v-for="(item, index) in history"
            :key="index"
          >
            <div @click.stop="() => getInfo(item)" class="button">
              {{ item }}
            </div>
            <van-icon
              class="delItemIcon"
              @click.stop="() => deleteOneHistory(item)"
              name="cross"
            />
          </div>
        </div>
        <van-empty description="没有搜索历史" v-else />
      </div>

      <!-- 搜索结果 -->
      <div v-else class="tab">
        <van-tabs v-model="active" @change="tabChange">
          <van-tab title="商品" name="goods">
            <van-loading size="24px" v-show="seachgoodloading" vertical>
              加载中...
            </van-loading>
            <van-empty
              description="没有搜索到相关商品"
              v-if="this.nothing && !this.list.length"
            />
            <div v-show="list.length">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="searchgoods"
                :offset="50"
                :immediate-check="false"
              >
                <van-card
                  v-for="item in list"
                  :key="item.id"
                  @click="commditDetail(item)"
                >
                  <!-- :title="item.name" -->
                  <template #title>
                    <span v-html="item.name"></span>
                  </template>
                  <template #thumb>
                    <van-image v-if="findFileType(item)===1" :src="findUrl(item)" />
                    <video @click.stop="(e)=>videoStop(e)" v-if="findFileType(item)===2" :src="findUrl(item)" controls class="videoStyle"></video>
                  </template>
                  <template #price>
                    <span>￥{{ (item.price||0).toFixed(2) }}</span>
                  </template>
                </van-card>
              </van-list>
            </div>
          </van-tab>
          <van-tab title="店铺" name="store">
            <van-loading size="24px" v-show="seachstoreloading" vertical>
              加载中...
            </van-loading>
            <van-empty
              description="没有搜索到相关店铺"
              v-if="this.storenothing && !this.storelist.length"
            />
            <div  v-show="storelist.length">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="searchstore"
                :offset="50"
                :immediate-check="false"
              >
                <Store v-show="this.storelist.length" :data="this.storelist" />
              </van-list>
            </div>

          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import {
  queryItemList,
  stoteItemList,
  queryHistoryList,
  addHistoryItem,
  deleteHistoryList,
  deleteHistoryItem,
} from "./service";
import Shop from "./productlist.vue";
import Store from "./shoplist.vue";
import delIcon from "../../assets/icon/search_delete_icon@2x.png";
import defaultImg from "../../assets/icon/store-touxiang-icon@2x.png";
import { Toast } from "vant";

export default {
  components: { Search, Store, Shop },
  data() {
    return {
      delIcon,
      defaultImg,
      searchData: "",
      seachgoodloading: false,
      seachstoreloading: false,
      show: true,
      nothing: false,
      storenothing: false,
      loading: false,
      finished: false,
      goodsPage: 1,
      storePage: 1,
      active: 2,
      history: [],
      list: [],
      storelist: [],
    };
  },
  mounted() {
    this.determinePageSource();
    this.queryHistory();
  },
  methods: {
    findFileType(item){
      return item?.mediaList? item.mediaList[0].type : 1
    },
    videoStop(e){
      e.stopPropagation()
    },
    findUrl(item) {
      return item?.mediaList && item.mediaList[0]?.url
        ? item.mediaList[0].url
        : this.defaultImg;
    },

    // 判断页面来源
    determinePageSource() {
      const {
        query: { type },
      } = this.$route;
      const typeMappingTabName = {
        defaultSearch: "goods",
        storeSearch: "store",
        goodsSearch: "goods",
      };
      if (type) {
        this.active = typeMappingTabName[type];
      } else {
        this.active = "goods";
      }
    },

    // 跳转详情
    commditDetail(item) {
      this.$router.push(
        `shopdetails?itemCode=${item.itemCode}&storeCode=${item.storeCode}`
      );
    },

    // tab切换
    tabChange(value) {
      this.active = value;
    },

    // 点击搜索
    onSearch() {
      this.getInfo(this.searchData);
    },

    // 搜索
    getInfo(searchvalue) {
      this.queryItemList(searchvalue);
      this.stoteItemList(searchvalue);
    },

    // 商品
    async queryItemList(searchvalue) {
      this.seachgoodloading = true;
      const res = await queryItemList(searchvalue, 1);
      this.seachgoodloading = false;
      if (res.status === "0") {
        if (res.data.items.length < 10) {
          this.finished = true;
        }
        this.list = res.data.items;
        this.goodsPage = Number(res.data.page);
        this.loading = false;
        this.show = false;
        this.nothing = true;
        if (this.list.length !== 0) {
          this.nothing = false;
        }
        searchvalue && addHistoryItem(searchvalue);
        this.queryHistory();
      } else {
        this.loading = false;
        this.finished = true;
        Toast.fail(res.msg);
      }
    },

    async searchgoods() {
      this.loading = true;
      if (!this.finished) {
        this.goodsPage = this.goodsPage + 1;
      }
      const res = await queryItemList(searchvalue, this.goodsPage);
      if (res.status === "0") {
        if (res.data.items.length < 10) {
          this.finished = true;
        }
        this.list = this.list.concat(res.data.items);
        this.goodsPage = Number(res.data.page);
        this.loading = false;
        this.show = false;
        this.nothing = true;
        if (this.list.length !== 0) {
          this.nothing = false;
        }
        searchvalue && addHistoryItem(searchvalue);
        this.queryHistory();
      } else {
        this.loading = false;
        this.finished = true;
        Toast.fail(res.msg);
      }
    },
    // 查询历史
    async queryHistory(searchvalue) {
      const res = await queryHistoryList(searchvalue);
      if (res.status === "0") {
        this.history = res.data;
      }
    },

    // 查询店铺
    async stoteItemList(searchvalue) {
      this.seachstoreloading = true;
      const res = await stoteItemList(searchvalue, 1);
      this.seachstoreloading = false;
      if (res.status === "0") {
        this.storePage = Number(res.data.page);
        this.storelist = res.data.items;
        this.show = false;
        this.storenothing = true;
        if (this.storelist.length !== 0) {
          this.storenothing = false;
        }
      } else {
        this.finished = true;
        this.loading = false;
        Toast.fail(res.msg);
      }
    },

    async searchstore() {
      this.loading = true;
      if (!this.finished) {
        this.storePage = this.storePage + 1;
      }
      const res = await stoteItemList(searchvalue, this.storePage);
      if (res.status === "0") {
        if (res.data.items.length < 10) {
          this.finished = true;
        }
        this.storelist = this.list.concat(res.data.items);
        this.storePage = Number(res.data.page);
        this.loading = false;
        this.show = false;
        this.nothing = true;
        if (this.storelist.length !== 0) {
          this.storenothing = false;
        }
      } else {
        this.loading = false;
        this.finished = true;
        Toast.fail(res.msg);
      }
    },
    // 全部删除
    async deleteHistory() {
      if (this.history.length === 0) {
        return;
      }
      const res = await deleteHistoryList();
      if (res.status === "0") {
        this.history = [];
      } else {
        Toast.fail(res.msg);
      }
    },

    // 删除单个历史搜索记录
    async deleteOneHistory(item) {
      const res = await deleteHistoryItem(item);
      if (res.status === "0") {
        this.queryHistory();
      }
    },
  },
};
</script>

<style lang="less" scoped>
#searchbox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .search {
    width: 100%;
    height: 54px;
    background-image: @GradientColor;
    .van-search {
      background-image: @GradientColor;
      .search-action {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #fff;
      }
      .van-search__action {
        background-color: transparent;
      }
    }
  }

  .content {
    flex: 1 1 0 ;
    width: 100%;
    height: 100%;
    overflow: auto;
    .historicalRecords {
      width: 100%;
      height: 100%;
      .historicalRecords-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 48px;
        padding: 0 16px;
        background-color: #fff;
        .historicalRecords-header-title {
          width: auto;
          height: 100%;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          color: #161823;
          line-height: 48px;
        }
        .historicalRecords-header-delBtn {
          width: auto;
          .van-icon {
            font-size: 16px;
          }
        }
      }
      .historicalRecords-content {
        padding: 16px;
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        .recordbutton {
          position: relative;
          margin: 4px;
          width: auto;
          height: auto;
          padding: 8px 16px;
          border-radius: 8px;
          background-color: #f9f9f9;
          .button {
            max-width: 130px;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            color: #666;
            overflow: hidden; /*超出部分隐藏*/
            text-overflow: ellipsis; /* 超出部分显示省略号 */
            white-space: nowrap; /*规定段落中的文本不进行换行 */
          }
          .delItemIcon {
            position: absolute;
            top: 12px;
            right: 0;
            font-size: 14px;
          }
        }
      }
    }

    /deep/ .tab {
      width: 100%;
      height: 100%;
      overflow: auto;
      background: @BackgroundColor;
      .van-tabs__content{
        width: 100%;
        height:calc(~'100% - 45px');
        overflow: auto;
      }
      .van-tab__text {
        font-size: 16px;
        font-family: PingFangSC, PingFang;
      }
      .van-tab--active {
        color: #0095da;
      }
      .van-tabs__line {
        background-color: #0095da;
      }
      .van-tab__pane {
        height: 100%;
        overflow: auto;
        padding: 12px;
      }
      .van-card {
        border-radius: 8px;
        background-color: #fff;
        .van-card__thumb {
          width: 99px;
          height: 99px;
        }
        .van-card__content {
          margin-left: 4px;
          .van-card__title {
            width: 100%;
            height: 48px;
            max-height: 48px;
            line-height: 24px;
            font-size: 16px;
            font-weight: 500;
            font-family: PingFangSC, PingFangSC-Medium;
            color: #000;
            overflow: hidden;
            display: -webkit-box; //将元素设为盒子伸缩模型显示
            -webkit-box-orient: vertical; //伸缩方向设为垂直方向
            -webkit-line-clamp: 2; //超出2行隐藏，并显示省略号
          }
          .van-card__price {
            width: 100%;
            height: 24px;
            line-height: 24px;
            font-size: 16px;
            font-family: Montserrat, Montserrat-Medium;
            font-weight: 500;
            color: #ff3228;
          }
        }
      }
    }

    .text {
      overflow: hidden;
      .h3 {
        float: left;
        margin-left: 5%;
      }
      .h4 {
        float: right;
        margin-right: 5%;
        color: gray;
      }
    }

    .buttons {
      margin: 3% 3%;
      .recordbutton {
        display: inline-block;
        margin: 3%;
      }
      .recordbutton_outline {
        display: flex;
        align-items: center;
      }
      h4 {
        color: #000;
      }
    }
  }

  .videoStyle{
    width: 99px;
    height: 99px;
  }
}

input::-ms-input-placeholder{
        text-align: center;
}
input::-webkit-input-placeholder{
        text-align: center;
}
</style>
