<!--
 * @Autor: syq
 * @Date: 2021-07-11 15:22:57
-->
<template>
  <div id="storeCart">
    <div class="storeCart-item" v-for="item in this.data" :key="item.id">
      <div class="top">
        <div class="left">
          <img :src="findLogo(item)" alt="" />
        </div>
        <div class="right">
          <div class="right-top">
            <h4>{{ item.name }}</h4>
            <div class="right-top-btn" @click.stop="() => jumpstore(item)">
              <span>进入店铺</span>
              <van-icon :name="forwardIcon"></van-icon>
            </div>
          </div>
          <div class="right-content">
            <p>
              {{ findRemark(item) }}
            </p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <van-grid :border="false" :column-num="4">
          <van-grid-item
            v-for="product in item.itemResList"
            :key="product.index"
          >
            <CommodityCard2
              :Productname="product.name"
              :Price="product.price"
              :Src="product.mediaList[0].url"
              :fileType="findFileType(product)"
              :item="product"
            />
          </van-grid-item>
        </van-grid>
      </div>
    </div>
  </div>
</template>

<script>
import forwardIcon from "../../assets/icon/forward_icon_Inno@2x.png";
import CommodityCard2 from "../../components/CommodityCard2.vue";
export default {
  components: { CommodityCard2 },
  data() {
    return {
      forwardIcon,
      // storename:"dianpu",
      // description:"ssssss",
      // spuResList:[
      //   {
      //     productname:"2222",
      //     price:"100"
      //   }
      // ]
    };
  },
  mounted() {},
  methods: {
    findFileType(item){
      return item?.mediaList? item.mediaList[0].type : 1
    },
    jumpstore(item) {
      this.$router.push({
        path: "/storehome",
        query: { storeCode: item.code },
      });
    },
    findLogo(item){
      return JSON.parse(JSON.parse(item.ext).logo)[0]?.thumbUrl ?? 'http://www.liby.com.cn/images/logo.png'
    },
    findRemark(item){
      return item?.remark ? item.remark : '暂无'
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
#storeCart {
  width: 100%;
  .storeCart-item {
    padding: 8px;
    margin-bottom: 8px;
    background: #fff;
    border-radius: 8px;
    .top {
      width: 100%;
      display: flex;
      background-color: #fff;
      border-radius: 8px 8px 0 0;
      .left {
        width: 32px;
        img {
          width: 32px;
          height: 32px;
        }
      }
      .right {
        flex: 1;
        .right-top {
          width: 100%;
          height: 24px;
          padding: 0 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          h4 {
            margin: 0;
            font-size: 16px;
            width: 170px;
            height: 24px;
            line-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .right-top-btn {
            width: auto;
            height: 24px;
            padding: 0 12px;
            span {
              display: inline-block;
              height: 24px;
              font-size: 12px;
              line-height: 24px;
              color: #0095da;
              vertical-align: bottom;
            }
            .van-icon {
              height: 24px;
              line-height: 24px;
              font-size: 12px;
            }
          }
        }
        .right-content {
          width: 100%;
          padding: 0 8px;
          p {
            margin: 0;
            width: 100%;
            max-height: 40px;
            line-height: 20px;
            font-size: 12px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            color: #ccc;
          }
        }
      }
    }
  }
}
</style>
