<!--
 * @Autor: syq
 * @Date: 2021-07-11 15:38:47
-->
<template>
  <div class="commodityCard" @click="commditDetail(item)">
    <img v-if="fileType===1" :src="Src" alt="" />
    <video @click.stop="(e)=>videoStop(e)" v-if="fileType===2" :src="Src" controls class="videoStyle"></video>
    <h4>{{ this.Productname }}</h4>
    <p>￥{{ this.Price }}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    Productname: {
      type: String, // 类型按需求
    },
    Price: {
      type: Number,
    },
    Src:{
      type:String,
    },
    fileType:{
      type: Number
    },
    item: {
      type: Object
    }
  },
  methods:{
    videoStop(e){
      e.stopPropagation()
    },
    // 跳转详情
    commditDetail() {
      const { itemCode, storeCode } = this.item;
      this.$router.push({
        path: "/shopdetails",
        query: { itemCode: itemCode, storeCode: storeCode },
      });
    },
  }
};
</script>
<style lang="less" scoped>
.commodityCard {
  width: auto;
  height: auto;
  img {
    width: 68px;
    height: 68px;
  }
  h4 {
    width: 68px;
    height: 14px;
    margin: 8px 0 0 0;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    margin: 0;
    color: #FF2731;
    
  }
  .videoStyle{
    width: 68px;
    height: 68px;
  }
}
</style>
